/* tslint:disable */
/* eslint-disable */
/**
 * Test swagger
 * Testing the Fastify swagger API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { SearchBigPicture } from './search-big-picture';

/**
 * 
 * @export
 * @interface PlaceSearchSuccessResponseDocumentsInnerComponentPlaceFetch
 */
export interface PlaceSearchSuccessResponseDocumentsInnerComponentPlaceFetch {
    /**
     * 
     * @type {string}
     * @memberof PlaceSearchSuccessResponseDocumentsInnerComponentPlaceFetch
     */
    'regionName'?: string;
    /**
     * 
     * @type {SearchBigPicture}
     * @memberof PlaceSearchSuccessResponseDocumentsInnerComponentPlaceFetch
     */
    'picture'?: SearchBigPicture;
    /**
     * 
     * @type {SearchBigPicture}
     * @memberof PlaceSearchSuccessResponseDocumentsInnerComponentPlaceFetch
     */
    'bgImage'?: SearchBigPicture;
    /**
     * 
     * @type {boolean}
     * @memberof PlaceSearchSuccessResponseDocumentsInnerComponentPlaceFetch
     */
    'hasCoupon'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PlaceSearchSuccessResponseDocumentsInnerComponentPlaceFetch
     */
    'operationStatus'?: PlaceSearchSuccessResponseDocumentsInnerComponentPlaceFetchOperationStatusEnum;
    /**
     * 
     * @type {Array<SearchBigPicture>}
     * @memberof PlaceSearchSuccessResponseDocumentsInnerComponentPlaceFetch
     */
    'images'?: Array<SearchBigPicture>;
}

export const PlaceSearchSuccessResponseDocumentsInnerComponentPlaceFetchOperationStatusEnum = {
    UNSPECIFIED: 'OPERATION_STATUS_UNSPECIFIED',
    OPEN: 'OPERATION_STATUS_OPEN',
    CLOSED_FOR_PREPARATION: 'OPERATION_STATUS_CLOSED_FOR_PREPARATION',
    BREAK_TIME: 'OPERATION_STATUS_BREAK_TIME',
    CLOSED_TODAY: 'OPERATION_STATUS_CLOSED_TODAY',
    OPEN_24_HOURS: 'OPERATION_STATUS_OPEN_24_HOURS'
} as const;

export type PlaceSearchSuccessResponseDocumentsInnerComponentPlaceFetchOperationStatusEnum = typeof PlaceSearchSuccessResponseDocumentsInnerComponentPlaceFetchOperationStatusEnum[keyof typeof PlaceSearchSuccessResponseDocumentsInnerComponentPlaceFetchOperationStatusEnum];


