/* tslint:disable */
/* eslint-disable */
/**
 * Test swagger
 * Testing the Fastify swagger API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { PlaceSearchSuccessResponseDocumentsInnerComponent } from './place-search-success-response-documents-inner-component';
import { PlaceSearchSuccessResponseDocumentsInnerMeta } from './place-search-success-response-documents-inner-meta';

/**
 * 
 * @export
 * @interface PlaceSearchSuccessResponseDocumentsInner
 */
export interface PlaceSearchSuccessResponseDocumentsInner {
    /**
     * 
     * @type {string}
     * @memberof PlaceSearchSuccessResponseDocumentsInner
     */
    'componentType': PlaceSearchSuccessResponseDocumentsInnerComponentTypeEnum;
    /**
     * 
     * @type {PlaceSearchSuccessResponseDocumentsInnerComponent}
     * @memberof PlaceSearchSuccessResponseDocumentsInner
     */
    'component': PlaceSearchSuccessResponseDocumentsInnerComponent;
    /**
     * 
     * @type {PlaceSearchSuccessResponseDocumentsInnerMeta}
     * @memberof PlaceSearchSuccessResponseDocumentsInner
     */
    'meta': PlaceSearchSuccessResponseDocumentsInnerMeta;
}

export const PlaceSearchSuccessResponseDocumentsInnerComponentTypeEnum = {
    UNSPECIFIED: 'PLACE_COMPONENT_TYPE_UNSPECIFIED',
    PLACE: 'PLACE_COMPONENT_TYPE_PLACE',
    BANNER: 'PLACE_COMPONENT_TYPE_BANNER'
} as const;

export type PlaceSearchSuccessResponseDocumentsInnerComponentTypeEnum = typeof PlaceSearchSuccessResponseDocumentsInnerComponentTypeEnum[keyof typeof PlaceSearchSuccessResponseDocumentsInnerComponentTypeEnum];


