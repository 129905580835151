/* tslint:disable */
/* eslint-disable */
/**
 * Test swagger
 * Testing the Fastify swagger API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiV1FleamarketKeywordNotificationInfoGet200Response } from '../models';
// @ts-ignore
import { ApiV1FleamarketKeywordNotificationInfoGet400Response } from '../models';
// @ts-ignore
import { ApiV1FleamarketKeywordSearchUserKeywordsPost200Response } from '../models';
// @ts-ignore
import { ApiV1FleamarketKeywordSearchUserKeywordsPostRequest } from '../models';
// @ts-ignore
import { ApiV1FleamarketRecommendedFilterGet200Response } from '../models';
// @ts-ignore
import { ApiV1FleamarketRegionRegionRangesGet200Response } from '../models';
// @ts-ignore
import { ApiV1FleamarketRelatedKeywordGet200Response } from '../models';
// @ts-ignore
import { ApiV1FleamarketSearchPost200Response } from '../models';
// @ts-ignore
import { ApiV1FleamarketSearchPostRequest } from '../models';
// @ts-ignore
import { ApiV1PreprocessSearchGet400Response } from '../models';
/**
 * FleamarketApi - axios parameter creator
 * @export
 */
export const FleamarketApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 중고거래 키워드 정보 (유저의 키워드 알림 등록 여부, 금지 키워드 여부, 키워드 알림 금지어 여부) 조회
         * @summary 중고거래 키워드 정보 (유저의 키워드 알림 등록 여부, 금지 키워드 여부, 키워드 알림 금지어 여부) 조회
         * @param {string} keyword 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xAuthKarrotUserId] x-auth-karrot-user-id
         * @param {string} [xAuthType] x-auth-type
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FleamarketKeywordNotificationInfoGet: async (keyword: string, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xAuthKarrotUserId?: string, xAuthType?: string, xForwardedFor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'keyword' is not null or undefined
            assertParamExists('apiV1FleamarketKeywordNotificationInfoGet', 'keyword', keyword)
            const localVarPath = `/api/v1/fleamarket/keyword/notification/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }

            if (xUserAgent !== undefined && xUserAgent !== null) {
                localVarHeaderParameter['x-user-agent'] = String(xUserAgent);
            }

            if (xCountryCode !== undefined && xCountryCode !== null) {
                localVarHeaderParameter['x-country-code'] = String(xCountryCode);
            }

            if (xAdId !== undefined && xAdId !== null) {
                localVarHeaderParameter['x-ad-id'] = String(xAdId);
            }

            if (xDeviceId !== undefined && xDeviceId !== null) {
                localVarHeaderParameter['x-device-id'] = String(xDeviceId);
            }

            if (xRequestId !== undefined && xRequestId !== null) {
                localVarHeaderParameter['x-request-id'] = String(xRequestId);
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['content-type'] = String(contentType);
            }

            if (xKarrotUserId !== undefined && xKarrotUserId !== null) {
                localVarHeaderParameter['x-karrot-user-id'] = String(xKarrotUserId);
            }

            if (xKarrotRegionId !== undefined && xKarrotRegionId !== null) {
                localVarHeaderParameter['x-karrot-region-id'] = String(xKarrotRegionId);
            }

            if (xKarrotSessionId !== undefined && xKarrotSessionId !== null) {
                localVarHeaderParameter['x-karrot-session-id'] = String(xKarrotSessionId);
            }

            if (xAuthKarrotUserId !== undefined && xAuthKarrotUserId !== null) {
                localVarHeaderParameter['x-auth-karrot-user-id'] = String(xAuthKarrotUserId);
            }

            if (xAuthType !== undefined && xAuthType !== null) {
                localVarHeaderParameter['x-auth-type'] = String(xAuthType);
            }

            if (xForwardedFor !== undefined && xForwardedFor !== null) {
                localVarHeaderParameter['x-forwarded-for'] = String(xForwardedFor);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 중고거래
         * @summary 키워드 알림 등록하기
         * @param {string} where 
         * @param {string} screenName 
         * @param {ApiV1FleamarketKeywordSearchUserKeywordsPostRequest} apiV1FleamarketKeywordSearchUserKeywordsPostRequest 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xAuthKarrotUserId] x-auth-karrot-user-id
         * @param {string} [xAuthType] x-auth-type
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FleamarketKeywordSearchUserKeywordsPost: async (where: string, screenName: string, apiV1FleamarketKeywordSearchUserKeywordsPostRequest: ApiV1FleamarketKeywordSearchUserKeywordsPostRequest, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xAuthKarrotUserId?: string, xAuthType?: string, xForwardedFor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'where' is not null or undefined
            assertParamExists('apiV1FleamarketKeywordSearchUserKeywordsPost', 'where', where)
            // verify required parameter 'screenName' is not null or undefined
            assertParamExists('apiV1FleamarketKeywordSearchUserKeywordsPost', 'screenName', screenName)
            // verify required parameter 'apiV1FleamarketKeywordSearchUserKeywordsPostRequest' is not null or undefined
            assertParamExists('apiV1FleamarketKeywordSearchUserKeywordsPost', 'apiV1FleamarketKeywordSearchUserKeywordsPostRequest', apiV1FleamarketKeywordSearchUserKeywordsPostRequest)
            const localVarPath = `/api/v1/fleamarket/keyword/search/user_keywords`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }

            if (screenName !== undefined) {
                localVarQueryParameter['screen_name'] = screenName;
            }

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }

            if (xUserAgent !== undefined && xUserAgent !== null) {
                localVarHeaderParameter['x-user-agent'] = String(xUserAgent);
            }

            if (xCountryCode !== undefined && xCountryCode !== null) {
                localVarHeaderParameter['x-country-code'] = String(xCountryCode);
            }

            if (xAdId !== undefined && xAdId !== null) {
                localVarHeaderParameter['x-ad-id'] = String(xAdId);
            }

            if (xDeviceId !== undefined && xDeviceId !== null) {
                localVarHeaderParameter['x-device-id'] = String(xDeviceId);
            }

            if (xRequestId !== undefined && xRequestId !== null) {
                localVarHeaderParameter['x-request-id'] = String(xRequestId);
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['content-type'] = String(contentType);
            }

            if (xKarrotUserId !== undefined && xKarrotUserId !== null) {
                localVarHeaderParameter['x-karrot-user-id'] = String(xKarrotUserId);
            }

            if (xKarrotRegionId !== undefined && xKarrotRegionId !== null) {
                localVarHeaderParameter['x-karrot-region-id'] = String(xKarrotRegionId);
            }

            if (xKarrotSessionId !== undefined && xKarrotSessionId !== null) {
                localVarHeaderParameter['x-karrot-session-id'] = String(xKarrotSessionId);
            }

            if (xAuthKarrotUserId !== undefined && xAuthKarrotUserId !== null) {
                localVarHeaderParameter['x-auth-karrot-user-id'] = String(xAuthKarrotUserId);
            }

            if (xAuthType !== undefined && xAuthType !== null) {
                localVarHeaderParameter['x-auth-type'] = String(xAuthType);
            }

            if (xForwardedFor !== undefined && xForwardedFor !== null) {
                localVarHeaderParameter['x-forwarded-for'] = String(xForwardedFor);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiV1FleamarketKeywordSearchUserKeywordsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 중고거래
         * @summary 중고거래 카테고리, 가격 추천 필터
         * @param {string} regionId 
         * @param {string} keyword 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xAuthKarrotUserId] x-auth-karrot-user-id
         * @param {string} [xAuthType] x-auth-type
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FleamarketRecommendedFilterGet: async (regionId: string, keyword: string, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xAuthKarrotUserId?: string, xAuthType?: string, xForwardedFor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('apiV1FleamarketRecommendedFilterGet', 'regionId', regionId)
            // verify required parameter 'keyword' is not null or undefined
            assertParamExists('apiV1FleamarketRecommendedFilterGet', 'keyword', keyword)
            const localVarPath = `/api/v1/fleamarket/recommended_filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (regionId !== undefined) {
                localVarQueryParameter['region_id'] = regionId;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }

            if (xUserAgent !== undefined && xUserAgent !== null) {
                localVarHeaderParameter['x-user-agent'] = String(xUserAgent);
            }

            if (xCountryCode !== undefined && xCountryCode !== null) {
                localVarHeaderParameter['x-country-code'] = String(xCountryCode);
            }

            if (xAdId !== undefined && xAdId !== null) {
                localVarHeaderParameter['x-ad-id'] = String(xAdId);
            }

            if (xDeviceId !== undefined && xDeviceId !== null) {
                localVarHeaderParameter['x-device-id'] = String(xDeviceId);
            }

            if (xRequestId !== undefined && xRequestId !== null) {
                localVarHeaderParameter['x-request-id'] = String(xRequestId);
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['content-type'] = String(contentType);
            }

            if (xKarrotUserId !== undefined && xKarrotUserId !== null) {
                localVarHeaderParameter['x-karrot-user-id'] = String(xKarrotUserId);
            }

            if (xKarrotRegionId !== undefined && xKarrotRegionId !== null) {
                localVarHeaderParameter['x-karrot-region-id'] = String(xKarrotRegionId);
            }

            if (xKarrotSessionId !== undefined && xKarrotSessionId !== null) {
                localVarHeaderParameter['x-karrot-session-id'] = String(xKarrotSessionId);
            }

            if (xAuthKarrotUserId !== undefined && xAuthKarrotUserId !== null) {
                localVarHeaderParameter['x-auth-karrot-user-id'] = String(xAuthKarrotUserId);
            }

            if (xAuthType !== undefined && xAuthType !== null) {
                localVarHeaderParameter['x-auth-type'] = String(xAuthType);
            }

            if (xForwardedFor !== undefined && xForwardedFor !== null) {
                localVarHeaderParameter['x-forwarded-for'] = String(xForwardedFor);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 중고거래
         * @summary 중고거래 필터, 지역 범위
         * @param {string} regionId 
         * @param {string} rangeType 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xAuthKarrotUserId] x-auth-karrot-user-id
         * @param {string} [xAuthType] x-auth-type
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FleamarketRegionRegionRangesGet: async (regionId: string, rangeType: string, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xAuthKarrotUserId?: string, xAuthType?: string, xForwardedFor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('apiV1FleamarketRegionRegionRangesGet', 'regionId', regionId)
            // verify required parameter 'rangeType' is not null or undefined
            assertParamExists('apiV1FleamarketRegionRegionRangesGet', 'rangeType', rangeType)
            const localVarPath = `/api/v1/fleamarket/region/region_ranges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (regionId !== undefined) {
                localVarQueryParameter['region_id'] = regionId;
            }

            if (rangeType !== undefined) {
                localVarQueryParameter['range_type'] = rangeType;
            }

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }

            if (xUserAgent !== undefined && xUserAgent !== null) {
                localVarHeaderParameter['x-user-agent'] = String(xUserAgent);
            }

            if (xCountryCode !== undefined && xCountryCode !== null) {
                localVarHeaderParameter['x-country-code'] = String(xCountryCode);
            }

            if (xAdId !== undefined && xAdId !== null) {
                localVarHeaderParameter['x-ad-id'] = String(xAdId);
            }

            if (xDeviceId !== undefined && xDeviceId !== null) {
                localVarHeaderParameter['x-device-id'] = String(xDeviceId);
            }

            if (xRequestId !== undefined && xRequestId !== null) {
                localVarHeaderParameter['x-request-id'] = String(xRequestId);
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['content-type'] = String(contentType);
            }

            if (xKarrotUserId !== undefined && xKarrotUserId !== null) {
                localVarHeaderParameter['x-karrot-user-id'] = String(xKarrotUserId);
            }

            if (xKarrotRegionId !== undefined && xKarrotRegionId !== null) {
                localVarHeaderParameter['x-karrot-region-id'] = String(xKarrotRegionId);
            }

            if (xKarrotSessionId !== undefined && xKarrotSessionId !== null) {
                localVarHeaderParameter['x-karrot-session-id'] = String(xKarrotSessionId);
            }

            if (xAuthKarrotUserId !== undefined && xAuthKarrotUserId !== null) {
                localVarHeaderParameter['x-auth-karrot-user-id'] = String(xAuthKarrotUserId);
            }

            if (xAuthType !== undefined && xAuthType !== null) {
                localVarHeaderParameter['x-auth-type'] = String(xAuthType);
            }

            if (xForwardedFor !== undefined && xForwardedFor !== null) {
                localVarHeaderParameter['x-forwarded-for'] = String(xForwardedFor);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 중고거래
         * @summary 중고거래 연관 검색어(TOP, FILTER 용) 조회
         * @param {string} regionId 
         * @param {string} keyword 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xAuthKarrotUserId] x-auth-karrot-user-id
         * @param {string} [xAuthType] x-auth-type
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FleamarketRelatedKeywordGet: async (regionId: string, keyword: string, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xAuthKarrotUserId?: string, xAuthType?: string, xForwardedFor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('apiV1FleamarketRelatedKeywordGet', 'regionId', regionId)
            // verify required parameter 'keyword' is not null or undefined
            assertParamExists('apiV1FleamarketRelatedKeywordGet', 'keyword', keyword)
            const localVarPath = `/api/v1/fleamarket/related_keyword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (regionId !== undefined) {
                localVarQueryParameter['region_id'] = regionId;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }

            if (xUserAgent !== undefined && xUserAgent !== null) {
                localVarHeaderParameter['x-user-agent'] = String(xUserAgent);
            }

            if (xCountryCode !== undefined && xCountryCode !== null) {
                localVarHeaderParameter['x-country-code'] = String(xCountryCode);
            }

            if (xAdId !== undefined && xAdId !== null) {
                localVarHeaderParameter['x-ad-id'] = String(xAdId);
            }

            if (xDeviceId !== undefined && xDeviceId !== null) {
                localVarHeaderParameter['x-device-id'] = String(xDeviceId);
            }

            if (xRequestId !== undefined && xRequestId !== null) {
                localVarHeaderParameter['x-request-id'] = String(xRequestId);
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['content-type'] = String(contentType);
            }

            if (xKarrotUserId !== undefined && xKarrotUserId !== null) {
                localVarHeaderParameter['x-karrot-user-id'] = String(xKarrotUserId);
            }

            if (xKarrotRegionId !== undefined && xKarrotRegionId !== null) {
                localVarHeaderParameter['x-karrot-region-id'] = String(xKarrotRegionId);
            }

            if (xKarrotSessionId !== undefined && xKarrotSessionId !== null) {
                localVarHeaderParameter['x-karrot-session-id'] = String(xKarrotSessionId);
            }

            if (xAuthKarrotUserId !== undefined && xAuthKarrotUserId !== null) {
                localVarHeaderParameter['x-auth-karrot-user-id'] = String(xAuthKarrotUserId);
            }

            if (xAuthType !== undefined && xAuthType !== null) {
                localVarHeaderParameter['x-auth-type'] = String(xAuthType);
            }

            if (xForwardedFor !== undefined && xForwardedFor !== null) {
                localVarHeaderParameter['x-forwarded-for'] = String(xForwardedFor);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 중고거래
         * @summary 중고거래 게시글 검색
         * @param {ApiV1FleamarketSearchPostRequest} apiV1FleamarketSearchPostRequest 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xAuthKarrotUserId] x-auth-karrot-user-id
         * @param {string} [xAuthType] x-auth-type
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FleamarketSearchPost: async (apiV1FleamarketSearchPostRequest: ApiV1FleamarketSearchPostRequest, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xAuthKarrotUserId?: string, xAuthType?: string, xForwardedFor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiV1FleamarketSearchPostRequest' is not null or undefined
            assertParamExists('apiV1FleamarketSearchPost', 'apiV1FleamarketSearchPostRequest', apiV1FleamarketSearchPostRequest)
            const localVarPath = `/api/v1/fleamarket/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }

            if (xUserAgent !== undefined && xUserAgent !== null) {
                localVarHeaderParameter['x-user-agent'] = String(xUserAgent);
            }

            if (xCountryCode !== undefined && xCountryCode !== null) {
                localVarHeaderParameter['x-country-code'] = String(xCountryCode);
            }

            if (xAdId !== undefined && xAdId !== null) {
                localVarHeaderParameter['x-ad-id'] = String(xAdId);
            }

            if (xDeviceId !== undefined && xDeviceId !== null) {
                localVarHeaderParameter['x-device-id'] = String(xDeviceId);
            }

            if (xRequestId !== undefined && xRequestId !== null) {
                localVarHeaderParameter['x-request-id'] = String(xRequestId);
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['content-type'] = String(contentType);
            }

            if (xKarrotUserId !== undefined && xKarrotUserId !== null) {
                localVarHeaderParameter['x-karrot-user-id'] = String(xKarrotUserId);
            }

            if (xKarrotRegionId !== undefined && xKarrotRegionId !== null) {
                localVarHeaderParameter['x-karrot-region-id'] = String(xKarrotRegionId);
            }

            if (xKarrotSessionId !== undefined && xKarrotSessionId !== null) {
                localVarHeaderParameter['x-karrot-session-id'] = String(xKarrotSessionId);
            }

            if (xAuthKarrotUserId !== undefined && xAuthKarrotUserId !== null) {
                localVarHeaderParameter['x-auth-karrot-user-id'] = String(xAuthKarrotUserId);
            }

            if (xAuthType !== undefined && xAuthType !== null) {
                localVarHeaderParameter['x-auth-type'] = String(xAuthType);
            }

            if (xForwardedFor !== undefined && xForwardedFor !== null) {
                localVarHeaderParameter['x-forwarded-for'] = String(xForwardedFor);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiV1FleamarketSearchPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FleamarketApi - functional programming interface
 * @export
 */
export const FleamarketApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FleamarketApiAxiosParamCreator(configuration)
    return {
        /**
         * 중고거래 키워드 정보 (유저의 키워드 알림 등록 여부, 금지 키워드 여부, 키워드 알림 금지어 여부) 조회
         * @summary 중고거래 키워드 정보 (유저의 키워드 알림 등록 여부, 금지 키워드 여부, 키워드 알림 금지어 여부) 조회
         * @param {string} keyword 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xAuthKarrotUserId] x-auth-karrot-user-id
         * @param {string} [xAuthType] x-auth-type
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FleamarketKeywordNotificationInfoGet(keyword: string, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xAuthKarrotUserId?: string, xAuthType?: string, xForwardedFor?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiV1FleamarketKeywordNotificationInfoGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FleamarketKeywordNotificationInfoGet(keyword, xAuthToken, acceptLanguage, xUserAgent, xCountryCode, xAdId, xDeviceId, xRequestId, authorization, contentType, xKarrotUserId, xKarrotRegionId, xKarrotSessionId, xAuthKarrotUserId, xAuthType, xForwardedFor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 중고거래
         * @summary 키워드 알림 등록하기
         * @param {string} where 
         * @param {string} screenName 
         * @param {ApiV1FleamarketKeywordSearchUserKeywordsPostRequest} apiV1FleamarketKeywordSearchUserKeywordsPostRequest 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xAuthKarrotUserId] x-auth-karrot-user-id
         * @param {string} [xAuthType] x-auth-type
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FleamarketKeywordSearchUserKeywordsPost(where: string, screenName: string, apiV1FleamarketKeywordSearchUserKeywordsPostRequest: ApiV1FleamarketKeywordSearchUserKeywordsPostRequest, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xAuthKarrotUserId?: string, xAuthType?: string, xForwardedFor?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiV1FleamarketKeywordSearchUserKeywordsPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FleamarketKeywordSearchUserKeywordsPost(where, screenName, apiV1FleamarketKeywordSearchUserKeywordsPostRequest, xAuthToken, acceptLanguage, xUserAgent, xCountryCode, xAdId, xDeviceId, xRequestId, authorization, contentType, xKarrotUserId, xKarrotRegionId, xKarrotSessionId, xAuthKarrotUserId, xAuthType, xForwardedFor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 중고거래
         * @summary 중고거래 카테고리, 가격 추천 필터
         * @param {string} regionId 
         * @param {string} keyword 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xAuthKarrotUserId] x-auth-karrot-user-id
         * @param {string} [xAuthType] x-auth-type
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FleamarketRecommendedFilterGet(regionId: string, keyword: string, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xAuthKarrotUserId?: string, xAuthType?: string, xForwardedFor?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiV1FleamarketRecommendedFilterGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FleamarketRecommendedFilterGet(regionId, keyword, xAuthToken, acceptLanguage, xUserAgent, xCountryCode, xAdId, xDeviceId, xRequestId, authorization, contentType, xKarrotUserId, xKarrotRegionId, xKarrotSessionId, xAuthKarrotUserId, xAuthType, xForwardedFor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 중고거래
         * @summary 중고거래 필터, 지역 범위
         * @param {string} regionId 
         * @param {string} rangeType 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xAuthKarrotUserId] x-auth-karrot-user-id
         * @param {string} [xAuthType] x-auth-type
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FleamarketRegionRegionRangesGet(regionId: string, rangeType: string, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xAuthKarrotUserId?: string, xAuthType?: string, xForwardedFor?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiV1FleamarketRegionRegionRangesGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FleamarketRegionRegionRangesGet(regionId, rangeType, xAuthToken, acceptLanguage, xUserAgent, xCountryCode, xAdId, xDeviceId, xRequestId, authorization, contentType, xKarrotUserId, xKarrotRegionId, xKarrotSessionId, xAuthKarrotUserId, xAuthType, xForwardedFor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 중고거래
         * @summary 중고거래 연관 검색어(TOP, FILTER 용) 조회
         * @param {string} regionId 
         * @param {string} keyword 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xAuthKarrotUserId] x-auth-karrot-user-id
         * @param {string} [xAuthType] x-auth-type
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FleamarketRelatedKeywordGet(regionId: string, keyword: string, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xAuthKarrotUserId?: string, xAuthType?: string, xForwardedFor?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiV1FleamarketRelatedKeywordGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FleamarketRelatedKeywordGet(regionId, keyword, xAuthToken, acceptLanguage, xUserAgent, xCountryCode, xAdId, xDeviceId, xRequestId, authorization, contentType, xKarrotUserId, xKarrotRegionId, xKarrotSessionId, xAuthKarrotUserId, xAuthType, xForwardedFor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 중고거래
         * @summary 중고거래 게시글 검색
         * @param {ApiV1FleamarketSearchPostRequest} apiV1FleamarketSearchPostRequest 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xAuthKarrotUserId] x-auth-karrot-user-id
         * @param {string} [xAuthType] x-auth-type
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FleamarketSearchPost(apiV1FleamarketSearchPostRequest: ApiV1FleamarketSearchPostRequest, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xAuthKarrotUserId?: string, xAuthType?: string, xForwardedFor?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiV1FleamarketSearchPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FleamarketSearchPost(apiV1FleamarketSearchPostRequest, xAuthToken, acceptLanguage, xUserAgent, xCountryCode, xAdId, xDeviceId, xRequestId, authorization, contentType, xKarrotUserId, xKarrotRegionId, xKarrotSessionId, xAuthKarrotUserId, xAuthType, xForwardedFor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FleamarketApi - factory interface
 * @export
 */
export const FleamarketApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FleamarketApiFp(configuration)
    return {
        /**
         * 중고거래 키워드 정보 (유저의 키워드 알림 등록 여부, 금지 키워드 여부, 키워드 알림 금지어 여부) 조회
         * @summary 중고거래 키워드 정보 (유저의 키워드 알림 등록 여부, 금지 키워드 여부, 키워드 알림 금지어 여부) 조회
         * @param {string} keyword 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xAuthKarrotUserId] x-auth-karrot-user-id
         * @param {string} [xAuthType] x-auth-type
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FleamarketKeywordNotificationInfoGet(keyword: string, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xAuthKarrotUserId?: string, xAuthType?: string, xForwardedFor?: string, options?: any): AxiosPromise<ApiV1FleamarketKeywordNotificationInfoGet200Response> {
            return localVarFp.apiV1FleamarketKeywordNotificationInfoGet(keyword, xAuthToken, acceptLanguage, xUserAgent, xCountryCode, xAdId, xDeviceId, xRequestId, authorization, contentType, xKarrotUserId, xKarrotRegionId, xKarrotSessionId, xAuthKarrotUserId, xAuthType, xForwardedFor, options).then((request) => request(axios, basePath));
        },
        /**
         * 중고거래
         * @summary 키워드 알림 등록하기
         * @param {string} where 
         * @param {string} screenName 
         * @param {ApiV1FleamarketKeywordSearchUserKeywordsPostRequest} apiV1FleamarketKeywordSearchUserKeywordsPostRequest 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xAuthKarrotUserId] x-auth-karrot-user-id
         * @param {string} [xAuthType] x-auth-type
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FleamarketKeywordSearchUserKeywordsPost(where: string, screenName: string, apiV1FleamarketKeywordSearchUserKeywordsPostRequest: ApiV1FleamarketKeywordSearchUserKeywordsPostRequest, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xAuthKarrotUserId?: string, xAuthType?: string, xForwardedFor?: string, options?: any): AxiosPromise<ApiV1FleamarketKeywordSearchUserKeywordsPost200Response> {
            return localVarFp.apiV1FleamarketKeywordSearchUserKeywordsPost(where, screenName, apiV1FleamarketKeywordSearchUserKeywordsPostRequest, xAuthToken, acceptLanguage, xUserAgent, xCountryCode, xAdId, xDeviceId, xRequestId, authorization, contentType, xKarrotUserId, xKarrotRegionId, xKarrotSessionId, xAuthKarrotUserId, xAuthType, xForwardedFor, options).then((request) => request(axios, basePath));
        },
        /**
         * 중고거래
         * @summary 중고거래 카테고리, 가격 추천 필터
         * @param {string} regionId 
         * @param {string} keyword 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xAuthKarrotUserId] x-auth-karrot-user-id
         * @param {string} [xAuthType] x-auth-type
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FleamarketRecommendedFilterGet(regionId: string, keyword: string, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xAuthKarrotUserId?: string, xAuthType?: string, xForwardedFor?: string, options?: any): AxiosPromise<ApiV1FleamarketRecommendedFilterGet200Response> {
            return localVarFp.apiV1FleamarketRecommendedFilterGet(regionId, keyword, xAuthToken, acceptLanguage, xUserAgent, xCountryCode, xAdId, xDeviceId, xRequestId, authorization, contentType, xKarrotUserId, xKarrotRegionId, xKarrotSessionId, xAuthKarrotUserId, xAuthType, xForwardedFor, options).then((request) => request(axios, basePath));
        },
        /**
         * 중고거래
         * @summary 중고거래 필터, 지역 범위
         * @param {string} regionId 
         * @param {string} rangeType 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xAuthKarrotUserId] x-auth-karrot-user-id
         * @param {string} [xAuthType] x-auth-type
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FleamarketRegionRegionRangesGet(regionId: string, rangeType: string, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xAuthKarrotUserId?: string, xAuthType?: string, xForwardedFor?: string, options?: any): AxiosPromise<ApiV1FleamarketRegionRegionRangesGet200Response> {
            return localVarFp.apiV1FleamarketRegionRegionRangesGet(regionId, rangeType, xAuthToken, acceptLanguage, xUserAgent, xCountryCode, xAdId, xDeviceId, xRequestId, authorization, contentType, xKarrotUserId, xKarrotRegionId, xKarrotSessionId, xAuthKarrotUserId, xAuthType, xForwardedFor, options).then((request) => request(axios, basePath));
        },
        /**
         * 중고거래
         * @summary 중고거래 연관 검색어(TOP, FILTER 용) 조회
         * @param {string} regionId 
         * @param {string} keyword 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xAuthKarrotUserId] x-auth-karrot-user-id
         * @param {string} [xAuthType] x-auth-type
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FleamarketRelatedKeywordGet(regionId: string, keyword: string, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xAuthKarrotUserId?: string, xAuthType?: string, xForwardedFor?: string, options?: any): AxiosPromise<ApiV1FleamarketRelatedKeywordGet200Response> {
            return localVarFp.apiV1FleamarketRelatedKeywordGet(regionId, keyword, xAuthToken, acceptLanguage, xUserAgent, xCountryCode, xAdId, xDeviceId, xRequestId, authorization, contentType, xKarrotUserId, xKarrotRegionId, xKarrotSessionId, xAuthKarrotUserId, xAuthType, xForwardedFor, options).then((request) => request(axios, basePath));
        },
        /**
         * 중고거래
         * @summary 중고거래 게시글 검색
         * @param {ApiV1FleamarketSearchPostRequest} apiV1FleamarketSearchPostRequest 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xAuthKarrotUserId] x-auth-karrot-user-id
         * @param {string} [xAuthType] x-auth-type
         * @param {string} [xForwardedFor] x-forwarded-for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FleamarketSearchPost(apiV1FleamarketSearchPostRequest: ApiV1FleamarketSearchPostRequest, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xAuthKarrotUserId?: string, xAuthType?: string, xForwardedFor?: string, options?: any): AxiosPromise<ApiV1FleamarketSearchPost200Response> {
            return localVarFp.apiV1FleamarketSearchPost(apiV1FleamarketSearchPostRequest, xAuthToken, acceptLanguage, xUserAgent, xCountryCode, xAdId, xDeviceId, xRequestId, authorization, contentType, xKarrotUserId, xKarrotRegionId, xKarrotSessionId, xAuthKarrotUserId, xAuthType, xForwardedFor, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1FleamarketKeywordNotificationInfoGet operation in FleamarketApi.
 * @export
 * @interface FleamarketApiApiV1FleamarketKeywordNotificationInfoGetRequest
 */
export interface FleamarketApiApiV1FleamarketKeywordNotificationInfoGetRequest {
    /**
     * 
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketKeywordNotificationInfoGet
     */
    readonly keyword: string

    /**
     * x-auth-token
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketKeywordNotificationInfoGet
     */
    readonly xAuthToken?: string

    /**
     * accept-language
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketKeywordNotificationInfoGet
     */
    readonly acceptLanguage?: string

    /**
     * x-user-agent
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketKeywordNotificationInfoGet
     */
    readonly xUserAgent?: string

    /**
     * x-country-code
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketKeywordNotificationInfoGet
     */
    readonly xCountryCode?: string

    /**
     * x-ad-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketKeywordNotificationInfoGet
     */
    readonly xAdId?: string

    /**
     * x-device-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketKeywordNotificationInfoGet
     */
    readonly xDeviceId?: string

    /**
     * x-request-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketKeywordNotificationInfoGet
     */
    readonly xRequestId?: string

    /**
     * authorization
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketKeywordNotificationInfoGet
     */
    readonly authorization?: string

    /**
     * content-type
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketKeywordNotificationInfoGet
     */
    readonly contentType?: string

    /**
     * x-karrot-user-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketKeywordNotificationInfoGet
     */
    readonly xKarrotUserId?: string

    /**
     * x-karrot-region-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketKeywordNotificationInfoGet
     */
    readonly xKarrotRegionId?: string

    /**
     * x-karrot-session-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketKeywordNotificationInfoGet
     */
    readonly xKarrotSessionId?: string

    /**
     * x-auth-karrot-user-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketKeywordNotificationInfoGet
     */
    readonly xAuthKarrotUserId?: string

    /**
     * x-auth-type
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketKeywordNotificationInfoGet
     */
    readonly xAuthType?: string

    /**
     * x-forwarded-for
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketKeywordNotificationInfoGet
     */
    readonly xForwardedFor?: string
}

/**
 * Request parameters for apiV1FleamarketKeywordSearchUserKeywordsPost operation in FleamarketApi.
 * @export
 * @interface FleamarketApiApiV1FleamarketKeywordSearchUserKeywordsPostRequest
 */
export interface FleamarketApiApiV1FleamarketKeywordSearchUserKeywordsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketKeywordSearchUserKeywordsPost
     */
    readonly where: string

    /**
     * 
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketKeywordSearchUserKeywordsPost
     */
    readonly screenName: string

    /**
     * 
     * @type {ApiV1FleamarketKeywordSearchUserKeywordsPostRequest}
     * @memberof FleamarketApiApiV1FleamarketKeywordSearchUserKeywordsPost
     */
    readonly apiV1FleamarketKeywordSearchUserKeywordsPostRequest: ApiV1FleamarketKeywordSearchUserKeywordsPostRequest

    /**
     * x-auth-token
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketKeywordSearchUserKeywordsPost
     */
    readonly xAuthToken?: string

    /**
     * accept-language
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketKeywordSearchUserKeywordsPost
     */
    readonly acceptLanguage?: string

    /**
     * x-user-agent
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketKeywordSearchUserKeywordsPost
     */
    readonly xUserAgent?: string

    /**
     * x-country-code
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketKeywordSearchUserKeywordsPost
     */
    readonly xCountryCode?: string

    /**
     * x-ad-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketKeywordSearchUserKeywordsPost
     */
    readonly xAdId?: string

    /**
     * x-device-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketKeywordSearchUserKeywordsPost
     */
    readonly xDeviceId?: string

    /**
     * x-request-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketKeywordSearchUserKeywordsPost
     */
    readonly xRequestId?: string

    /**
     * authorization
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketKeywordSearchUserKeywordsPost
     */
    readonly authorization?: string

    /**
     * content-type
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketKeywordSearchUserKeywordsPost
     */
    readonly contentType?: string

    /**
     * x-karrot-user-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketKeywordSearchUserKeywordsPost
     */
    readonly xKarrotUserId?: string

    /**
     * x-karrot-region-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketKeywordSearchUserKeywordsPost
     */
    readonly xKarrotRegionId?: string

    /**
     * x-karrot-session-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketKeywordSearchUserKeywordsPost
     */
    readonly xKarrotSessionId?: string

    /**
     * x-auth-karrot-user-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketKeywordSearchUserKeywordsPost
     */
    readonly xAuthKarrotUserId?: string

    /**
     * x-auth-type
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketKeywordSearchUserKeywordsPost
     */
    readonly xAuthType?: string

    /**
     * x-forwarded-for
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketKeywordSearchUserKeywordsPost
     */
    readonly xForwardedFor?: string
}

/**
 * Request parameters for apiV1FleamarketRecommendedFilterGet operation in FleamarketApi.
 * @export
 * @interface FleamarketApiApiV1FleamarketRecommendedFilterGetRequest
 */
export interface FleamarketApiApiV1FleamarketRecommendedFilterGetRequest {
    /**
     * 
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRecommendedFilterGet
     */
    readonly regionId: string

    /**
     * 
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRecommendedFilterGet
     */
    readonly keyword: string

    /**
     * x-auth-token
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRecommendedFilterGet
     */
    readonly xAuthToken?: string

    /**
     * accept-language
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRecommendedFilterGet
     */
    readonly acceptLanguage?: string

    /**
     * x-user-agent
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRecommendedFilterGet
     */
    readonly xUserAgent?: string

    /**
     * x-country-code
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRecommendedFilterGet
     */
    readonly xCountryCode?: string

    /**
     * x-ad-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRecommendedFilterGet
     */
    readonly xAdId?: string

    /**
     * x-device-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRecommendedFilterGet
     */
    readonly xDeviceId?: string

    /**
     * x-request-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRecommendedFilterGet
     */
    readonly xRequestId?: string

    /**
     * authorization
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRecommendedFilterGet
     */
    readonly authorization?: string

    /**
     * content-type
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRecommendedFilterGet
     */
    readonly contentType?: string

    /**
     * x-karrot-user-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRecommendedFilterGet
     */
    readonly xKarrotUserId?: string

    /**
     * x-karrot-region-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRecommendedFilterGet
     */
    readonly xKarrotRegionId?: string

    /**
     * x-karrot-session-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRecommendedFilterGet
     */
    readonly xKarrotSessionId?: string

    /**
     * x-auth-karrot-user-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRecommendedFilterGet
     */
    readonly xAuthKarrotUserId?: string

    /**
     * x-auth-type
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRecommendedFilterGet
     */
    readonly xAuthType?: string

    /**
     * x-forwarded-for
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRecommendedFilterGet
     */
    readonly xForwardedFor?: string
}

/**
 * Request parameters for apiV1FleamarketRegionRegionRangesGet operation in FleamarketApi.
 * @export
 * @interface FleamarketApiApiV1FleamarketRegionRegionRangesGetRequest
 */
export interface FleamarketApiApiV1FleamarketRegionRegionRangesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRegionRegionRangesGet
     */
    readonly regionId: string

    /**
     * 
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRegionRegionRangesGet
     */
    readonly rangeType: string

    /**
     * x-auth-token
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRegionRegionRangesGet
     */
    readonly xAuthToken?: string

    /**
     * accept-language
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRegionRegionRangesGet
     */
    readonly acceptLanguage?: string

    /**
     * x-user-agent
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRegionRegionRangesGet
     */
    readonly xUserAgent?: string

    /**
     * x-country-code
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRegionRegionRangesGet
     */
    readonly xCountryCode?: string

    /**
     * x-ad-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRegionRegionRangesGet
     */
    readonly xAdId?: string

    /**
     * x-device-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRegionRegionRangesGet
     */
    readonly xDeviceId?: string

    /**
     * x-request-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRegionRegionRangesGet
     */
    readonly xRequestId?: string

    /**
     * authorization
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRegionRegionRangesGet
     */
    readonly authorization?: string

    /**
     * content-type
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRegionRegionRangesGet
     */
    readonly contentType?: string

    /**
     * x-karrot-user-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRegionRegionRangesGet
     */
    readonly xKarrotUserId?: string

    /**
     * x-karrot-region-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRegionRegionRangesGet
     */
    readonly xKarrotRegionId?: string

    /**
     * x-karrot-session-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRegionRegionRangesGet
     */
    readonly xKarrotSessionId?: string

    /**
     * x-auth-karrot-user-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRegionRegionRangesGet
     */
    readonly xAuthKarrotUserId?: string

    /**
     * x-auth-type
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRegionRegionRangesGet
     */
    readonly xAuthType?: string

    /**
     * x-forwarded-for
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRegionRegionRangesGet
     */
    readonly xForwardedFor?: string
}

/**
 * Request parameters for apiV1FleamarketRelatedKeywordGet operation in FleamarketApi.
 * @export
 * @interface FleamarketApiApiV1FleamarketRelatedKeywordGetRequest
 */
export interface FleamarketApiApiV1FleamarketRelatedKeywordGetRequest {
    /**
     * 
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRelatedKeywordGet
     */
    readonly regionId: string

    /**
     * 
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRelatedKeywordGet
     */
    readonly keyword: string

    /**
     * x-auth-token
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRelatedKeywordGet
     */
    readonly xAuthToken?: string

    /**
     * accept-language
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRelatedKeywordGet
     */
    readonly acceptLanguage?: string

    /**
     * x-user-agent
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRelatedKeywordGet
     */
    readonly xUserAgent?: string

    /**
     * x-country-code
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRelatedKeywordGet
     */
    readonly xCountryCode?: string

    /**
     * x-ad-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRelatedKeywordGet
     */
    readonly xAdId?: string

    /**
     * x-device-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRelatedKeywordGet
     */
    readonly xDeviceId?: string

    /**
     * x-request-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRelatedKeywordGet
     */
    readonly xRequestId?: string

    /**
     * authorization
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRelatedKeywordGet
     */
    readonly authorization?: string

    /**
     * content-type
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRelatedKeywordGet
     */
    readonly contentType?: string

    /**
     * x-karrot-user-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRelatedKeywordGet
     */
    readonly xKarrotUserId?: string

    /**
     * x-karrot-region-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRelatedKeywordGet
     */
    readonly xKarrotRegionId?: string

    /**
     * x-karrot-session-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRelatedKeywordGet
     */
    readonly xKarrotSessionId?: string

    /**
     * x-auth-karrot-user-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRelatedKeywordGet
     */
    readonly xAuthKarrotUserId?: string

    /**
     * x-auth-type
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRelatedKeywordGet
     */
    readonly xAuthType?: string

    /**
     * x-forwarded-for
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketRelatedKeywordGet
     */
    readonly xForwardedFor?: string
}

/**
 * Request parameters for apiV1FleamarketSearchPost operation in FleamarketApi.
 * @export
 * @interface FleamarketApiApiV1FleamarketSearchPostRequest
 */
export interface FleamarketApiApiV1FleamarketSearchPostRequest {
    /**
     * 
     * @type {ApiV1FleamarketSearchPostRequest}
     * @memberof FleamarketApiApiV1FleamarketSearchPost
     */
    readonly apiV1FleamarketSearchPostRequest: ApiV1FleamarketSearchPostRequest

    /**
     * x-auth-token
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketSearchPost
     */
    readonly xAuthToken?: string

    /**
     * accept-language
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketSearchPost
     */
    readonly acceptLanguage?: string

    /**
     * x-user-agent
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketSearchPost
     */
    readonly xUserAgent?: string

    /**
     * x-country-code
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketSearchPost
     */
    readonly xCountryCode?: string

    /**
     * x-ad-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketSearchPost
     */
    readonly xAdId?: string

    /**
     * x-device-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketSearchPost
     */
    readonly xDeviceId?: string

    /**
     * x-request-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketSearchPost
     */
    readonly xRequestId?: string

    /**
     * authorization
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketSearchPost
     */
    readonly authorization?: string

    /**
     * content-type
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketSearchPost
     */
    readonly contentType?: string

    /**
     * x-karrot-user-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketSearchPost
     */
    readonly xKarrotUserId?: string

    /**
     * x-karrot-region-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketSearchPost
     */
    readonly xKarrotRegionId?: string

    /**
     * x-karrot-session-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketSearchPost
     */
    readonly xKarrotSessionId?: string

    /**
     * x-auth-karrot-user-id
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketSearchPost
     */
    readonly xAuthKarrotUserId?: string

    /**
     * x-auth-type
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketSearchPost
     */
    readonly xAuthType?: string

    /**
     * x-forwarded-for
     * @type {string}
     * @memberof FleamarketApiApiV1FleamarketSearchPost
     */
    readonly xForwardedFor?: string
}

/**
 * FleamarketApi - object-oriented interface
 * @export
 * @class FleamarketApi
 * @extends {BaseAPI}
 */
export class FleamarketApi extends BaseAPI {
    /**
     * 중고거래 키워드 정보 (유저의 키워드 알림 등록 여부, 금지 키워드 여부, 키워드 알림 금지어 여부) 조회
     * @summary 중고거래 키워드 정보 (유저의 키워드 알림 등록 여부, 금지 키워드 여부, 키워드 알림 금지어 여부) 조회
     * @param {FleamarketApiApiV1FleamarketKeywordNotificationInfoGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FleamarketApi
     */
    public apiV1FleamarketKeywordNotificationInfoGet(requestParameters: FleamarketApiApiV1FleamarketKeywordNotificationInfoGetRequest, options?: AxiosRequestConfig) {
        return FleamarketApiFp(this.configuration).apiV1FleamarketKeywordNotificationInfoGet(requestParameters.keyword, requestParameters.xAuthToken, requestParameters.acceptLanguage, requestParameters.xUserAgent, requestParameters.xCountryCode, requestParameters.xAdId, requestParameters.xDeviceId, requestParameters.xRequestId, requestParameters.authorization, requestParameters.contentType, requestParameters.xKarrotUserId, requestParameters.xKarrotRegionId, requestParameters.xKarrotSessionId, requestParameters.xAuthKarrotUserId, requestParameters.xAuthType, requestParameters.xForwardedFor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 중고거래
     * @summary 키워드 알림 등록하기
     * @param {FleamarketApiApiV1FleamarketKeywordSearchUserKeywordsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FleamarketApi
     */
    public apiV1FleamarketKeywordSearchUserKeywordsPost(requestParameters: FleamarketApiApiV1FleamarketKeywordSearchUserKeywordsPostRequest, options?: AxiosRequestConfig) {
        return FleamarketApiFp(this.configuration).apiV1FleamarketKeywordSearchUserKeywordsPost(requestParameters.where, requestParameters.screenName, requestParameters.apiV1FleamarketKeywordSearchUserKeywordsPostRequest, requestParameters.xAuthToken, requestParameters.acceptLanguage, requestParameters.xUserAgent, requestParameters.xCountryCode, requestParameters.xAdId, requestParameters.xDeviceId, requestParameters.xRequestId, requestParameters.authorization, requestParameters.contentType, requestParameters.xKarrotUserId, requestParameters.xKarrotRegionId, requestParameters.xKarrotSessionId, requestParameters.xAuthKarrotUserId, requestParameters.xAuthType, requestParameters.xForwardedFor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 중고거래
     * @summary 중고거래 카테고리, 가격 추천 필터
     * @param {FleamarketApiApiV1FleamarketRecommendedFilterGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FleamarketApi
     */
    public apiV1FleamarketRecommendedFilterGet(requestParameters: FleamarketApiApiV1FleamarketRecommendedFilterGetRequest, options?: AxiosRequestConfig) {
        return FleamarketApiFp(this.configuration).apiV1FleamarketRecommendedFilterGet(requestParameters.regionId, requestParameters.keyword, requestParameters.xAuthToken, requestParameters.acceptLanguage, requestParameters.xUserAgent, requestParameters.xCountryCode, requestParameters.xAdId, requestParameters.xDeviceId, requestParameters.xRequestId, requestParameters.authorization, requestParameters.contentType, requestParameters.xKarrotUserId, requestParameters.xKarrotRegionId, requestParameters.xKarrotSessionId, requestParameters.xAuthKarrotUserId, requestParameters.xAuthType, requestParameters.xForwardedFor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 중고거래
     * @summary 중고거래 필터, 지역 범위
     * @param {FleamarketApiApiV1FleamarketRegionRegionRangesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FleamarketApi
     */
    public apiV1FleamarketRegionRegionRangesGet(requestParameters: FleamarketApiApiV1FleamarketRegionRegionRangesGetRequest, options?: AxiosRequestConfig) {
        return FleamarketApiFp(this.configuration).apiV1FleamarketRegionRegionRangesGet(requestParameters.regionId, requestParameters.rangeType, requestParameters.xAuthToken, requestParameters.acceptLanguage, requestParameters.xUserAgent, requestParameters.xCountryCode, requestParameters.xAdId, requestParameters.xDeviceId, requestParameters.xRequestId, requestParameters.authorization, requestParameters.contentType, requestParameters.xKarrotUserId, requestParameters.xKarrotRegionId, requestParameters.xKarrotSessionId, requestParameters.xAuthKarrotUserId, requestParameters.xAuthType, requestParameters.xForwardedFor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 중고거래
     * @summary 중고거래 연관 검색어(TOP, FILTER 용) 조회
     * @param {FleamarketApiApiV1FleamarketRelatedKeywordGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FleamarketApi
     */
    public apiV1FleamarketRelatedKeywordGet(requestParameters: FleamarketApiApiV1FleamarketRelatedKeywordGetRequest, options?: AxiosRequestConfig) {
        return FleamarketApiFp(this.configuration).apiV1FleamarketRelatedKeywordGet(requestParameters.regionId, requestParameters.keyword, requestParameters.xAuthToken, requestParameters.acceptLanguage, requestParameters.xUserAgent, requestParameters.xCountryCode, requestParameters.xAdId, requestParameters.xDeviceId, requestParameters.xRequestId, requestParameters.authorization, requestParameters.contentType, requestParameters.xKarrotUserId, requestParameters.xKarrotRegionId, requestParameters.xKarrotSessionId, requestParameters.xAuthKarrotUserId, requestParameters.xAuthType, requestParameters.xForwardedFor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 중고거래
     * @summary 중고거래 게시글 검색
     * @param {FleamarketApiApiV1FleamarketSearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FleamarketApi
     */
    public apiV1FleamarketSearchPost(requestParameters: FleamarketApiApiV1FleamarketSearchPostRequest, options?: AxiosRequestConfig) {
        return FleamarketApiFp(this.configuration).apiV1FleamarketSearchPost(requestParameters.apiV1FleamarketSearchPostRequest, requestParameters.xAuthToken, requestParameters.acceptLanguage, requestParameters.xUserAgent, requestParameters.xCountryCode, requestParameters.xAdId, requestParameters.xDeviceId, requestParameters.xRequestId, requestParameters.authorization, requestParameters.contentType, requestParameters.xKarrotUserId, requestParameters.xKarrotRegionId, requestParameters.xKarrotSessionId, requestParameters.xAuthKarrotUserId, requestParameters.xAuthType, requestParameters.xForwardedFor, options).then((request) => request(this.axios, this.basePath));
    }
}
